<template>
  <modal
      name="conteudoTermosHistorico"
      height="auto"
      width="900"
      :scrollable="true"
      :clickToClose="false"
  >
    <CloseModal :close="close"/>
    <div class="modalbox">
      <div class="modalbox-content">
        <CTabs variant="tabs" class="nav-tabs-boxed">
          <CTab :title="`Histórico`">
            <div class="xrow">
              <div class="col-1-1">
                <div class="form-group">
                  <editor-conteudo :item="this.item"></editor-conteudo>
                </div>
              </div>
            </div>
          </CTab>
        </CTabs>
      </div>
    </div>
  </modal>
</template>
<script>
import CloseModal from "../../components/CloseModal";
import EditorConteudo from "../../components/EditorConteudo";

export default {
  name: "conteudoTermosHistorico",
  props: [
    "item",
    "close",
  ],
  components: {CloseModal, EditorConteudo},
};
</script>
