<template>
  <div>
    <Edit
        :item="currentItem"
        :create="createItem"
        :update="updateItem"
        :close="closeEdit"
        :descSingular="descSingular"
        :tiposTermo="tiposTermo"
        :historico="historico"
        :historicoPaginator="historicoPaginator"
        :confirmacoes="confirmacoes"
        :confirmacoesPaginator="confirmacoesPaginator"
        :loadHistorico="loadHistorico"
        :loadConfirmacoes="loadConfirmacoes"
    ></Edit>
    <div>
      <div class="content">
        <div class="content-wrapper">
          <Loading :isActive="loading"></Loading>
          <CTabs variant="tabs" class="nav-tabs-boxed">
            <CTab :title="descPlural">
              <div class="filters row" style="margin-top:0">
                <div class="col-1-1">
                  <div class="pull-right">
                    <button v-on:click="openCreate" class="button button-success button-block">
                      <fa-icon icon="plus"/>
                      Criar
                    </button>
                  </div>
                </div>
              </div>
              <div class="table-responsive">
                <table class="table table-hover">
                  <thead>
                  <tr>
                    <th>Imagem Principal</th>
                    <th>Título</th>
                    <th>Ativo</th>
                    <th>Ordem</th>
                    <th></th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="item in items">
                    <td>
                      <img class="img-thumbnail" :src="item.imagem_principal"/>
                    </td>
                    <td>{{ item.titulo }}</td>
                    <td>
                      <span class="label success" v-if="item.ativo">Ativo</span>
                      <span class="label error" v-else>Inativo</span>
                    </td>
                    <td>
                      <span class="label primary">{{ item.ordem }}</span>
                    </td>
                    <td class="actions">
                      <button type="button" class="button button-primary" v-on:click="openEdit(item)">
                        <fa-icon icon="pencil-alt"/>
                      </button>
                      <button type="button" name="button" v-on:click="deleteConfirm(item.id)"
                              class="button button-error">
                        <fa-icon icon="trash-alt"/>
                      </button>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </CTab>
          </CTabs>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import clone from 'just-clone';
import {get, post, put, del} from '@/helpers/apiRequest';
import Edit from './Edit';

const initial = () => ({
  titulo: null,
  descricao: null,
  ativo: 1,
  ordem: 1,
  tiposTermo: []
});

export default {
  name: 'termosLgpd',
  components: {
    Edit,
  },
  data() {
    return {
      descPlural: '',
      descSingular: '',
      loading: false,
      items: [],
      tiposTermo: [],
      modulos: [],
      currentItem: {},
      historico: {},
      historicoPaginator: {},
      confirmacoes: {},
      confirmacoesPaginator: {},
    };
  },
  methods: {
    loadDescricaoModulo() {
      const {modulos} = JSON.parse(localStorage.getItem('app'));
      const {descPlural, descSingular} = modulos.find(
          (m) => m.nome === 'ADMIN_PAGINA_TERMOS'
      );
      this.descPlural = descPlural;
      this.descSingular = descSingular;
    },
    loadTermosLgpd() {
      this.loading = true;
      get('/admin/pagina-termos')
          .then((json) => {
            this.items = json;
            this.loading = false;
          })
          .catch(() => (this.loading = false));
    },
    loadTiposTermo() {
      this.loading = true;
      get('/admin/tipos-termo')
          .then((json) => {
            this.tiposTermo = json;
            this.loading = false;
          })
          .catch(() => (this.loading = false));
    },
    openCreate() {
      this.currentItem = initial();
      this.$modal.show('termosLgpdEdit');
    },
    openEdit(item) {
      this.currentItem = clone(item);

      this.loadHistorico();
      this.loadConfirmacoes();

      this.$modal.show('termosLgpdEdit');
    },
    createItem(item) {
      this.loading = true;

      post('/admin/pagina-termos', item)
        .then((json) => {
          this.loading = false;

          this.closeEdit();
          this.loadTermosLgpd();

          this.$swal({
            title: 'Salvo com sucesso',
            text: json.message,
            showCancelButton: false,
            cancelButtonText: 'Fechar',
            showConfirmButton: true,
            icon: 'success',
          });
        })
        .catch((error) => {
          this.loading = false;

          this.$swal({
            title: 'Falha ao salvar',
            text: error.message,
            showCancelButton: true,
            cancelButtonText: 'Fechar',
            showConfirmButton: false,
            icon: 'error',
          });
        });
    },
    closeEdit() {
      this.$modal.hide('termosLgpdEdit');
    },
    updateItem() {
      this.loading = true;

      const {id} = this.currentItem;

      put(`/admin/pagina-termos/${id}`, this.currentItem)
          .then((json) => {
            this.loading = false;

            this.closeEdit();
            this.loadTermosLgpd();

            this.$swal({
              title: 'Salvo com sucesso',
              text: json.message,
              showCancelButton: false,
              cancelButtonText: 'Fechar',
              showConfirmButton: true,
              icon: 'success',
            });
          })
          .catch((error) => {
            this.loading = false;

            this.$swal({
              title: 'Falha ao salvar',
              text: error.message,
              showCancelButton: true,
              cancelButtonText: 'Fechar',
              showConfirmButton: false,
              icon: 'error',
            });
          });
    },
    deleteConfirm(id) {
      this.$swal({
        title: 'Confirma remover?',
        text: 'Essa ação não poderá ser desfeita',
        showCancelButton: true,
        icon: 'warning',
        confirmButtonText: 'Excluir',
        cancelButtonText: 'Cancelar',
      })
          .then((res) => {
            if (res.value) {
              this.loading = true;
              del(`/admin/pagina-termos/${id}`)
                  .then((json) => {
                    this.loading = false;
                    this.loadTermosLgpd();
                  })
                  .catch((err) => {
                    this.loading = false;
                    this.$swal({
                      title: 'Falha ao remover',
                      icon: 'error',
                    });
                  });
            }
          })
          .catch(() => {
          });
    },
    loadHistorico(page = 1, take = 12) {
      get(`/admin/pagina-termos/historico-paginate/${this.currentItem.id}?page=${page}&take=${take}`)
          .then((response) => {
            this.historico = response.itens;
            this.historicoPaginator = response.paginator;
          })
          .catch((e) => {
          })
    },
    loadConfirmacoes(page = 1, take = 12, term = '') {
      get(`/admin/pagina-termos/confirmacoes-paginate/${this.currentItem.id}?take=${take}&page=${page}&q=${term}`)
          .then((response) => {
            this.confirmacoes = response.itens;
            this.confirmacoesPaginator = response.paginator;
          })
          .catch((e) => {
          })
    },
  },
  beforeMount() {
    this.loading = true;
    this.loadTermosLgpd();
    this.loadDescricaoModulo();
    this.loadTiposTermo();
  },
};
</script>
