<template>
  <div class="xrow">
    <ConteudoHistorico
        :item="this.currentHistorico"
        :close="closeEditor"
    ></ConteudoHistorico>
    <div class="table-responsive">
      <datatable
          :columns="table.columns"
          :data="historico"
          class="table table-hover"
      >
        <template slot-scope="{ row }">
          <tr>
            <td>
              {{ row.colaborador_id }} - {{ row.colaborador_nome }}
            </td>
            <td>
              {{ row.datahora_criacao }}
            </td>
            <td>
              <button class="btn-sm btn-primary" v-on:click="openEditor(row)">Visualizar</button>
            </td>
          </tr>
        </template>
      </datatable>
    </div>
    <pagination
        :data="historicoPaginator"
        :limit="1"
        @pagination-change-page="loadHistorico"
    />
  </div>
</template>

<script>
import ConteudoHistorico from '@/views/TermosLgpd/ConteudoTermos.vue';

export default {
  name: 'HistoricoTab',
  props: ['historico', 'historicoPaginator', 'loadHistorico'],
  components: {ConteudoHistorico},
  methods: {
    openEditor(currentHistorico) {
      this.currentHistorico = currentHistorico;
      this.$modal.show('conteudoTermosHistorico');
    },
    closeEditor() {
      this.$modal.hide('conteudoTermosHistorico');
    },
  },
  data() {
    return {
      table: {
        filter: "",
        columns: [
          {label: "Responsável", field: "colaborador_nome", sortable: false},
          {label: "Data de modificação", field: "datahora_criacao", sortable: false},
          {label: "", field: "", sortable: false},
        ],
        paginator: {},
      },
      currentHistorico: {},

    }
  }
};
</script>