<style scoped>
</style>
<template>
  <modal
      name="termosLgpdEdit"
      height="auto"
      width="1000"
      :scrollable="true"
      :clickToClose="false"
  >
    <CloseModal :close="close"/>
    <div class="modalbox">
      <div class="modalbox-content">
        <CTabs variant="tabs" class="nav-tabs-boxed">
          <CTab :title="`Editando ${descSingular}`">
            <CAlert show color="warning" v-show="item.tipo_uso === 'TERMOS_USO'">
              O termo de uso é apresentado após o login na plataforma e exige o aceite do usuário. Caso o usuário não aceite, ele será redirecionado para a tela de login.
              <br>
              <br>
              Atenção: ao realizar alterações no conteúdo, os aceites anteriores serão invalidados e os usuários precisarão concordar novamente ao acessar a plataforma.
            </CAlert>
            <div class="xrow">
              <div class="col-1-3">
                <div class="form-group">
                  <label for="">Título</label>
                  <input class="form-control" v-model="item.titulo"/>
                  <div class="message">
                    {{ validation.firstError("item.titulo") }}
                  </div>
                </div>
              </div>
              <div class="col-1-3">
                <div class="form-group">
                  <label for="">Ativo</label>
                  <select class="form-control" v-model="item.ativo">
                    <option value="1">Sim</option>
                    <option value="0">Não</option>
                  </select>
                  <div class="message">
                    {{ validation.firstError("item.ativo") }}
                  </div>
                </div>
              </div>
              <div class="col-1-4">
                <div class="form-group">
                  <label for="">Ordem</label>
                  <input
                      type="number"
                      class="form-control"
                      v-model="item.ordem"
                  />
                  <div class="message">
                    {{ validation.firstError("item.ordem") }}
                  </div>
                </div>
              </div>
              <div class="col-1-3">
                <div class="form-group">
                  <label for="">Tipo de Uso</label>
                  <select class="form-control" v-model="item.tipo_uso">
                    <option v-for="(value, key) in tiposTermo" :value="key">
                      {{ value }}
                    </option>
                  </select>
                  <div class="message">
                    {{ validation.firstError("item.tipo_uso") }}
                  </div>
                </div>
              </div>
              <div class="col-1-3">
                <div class="form-group">
                  <label for="">Meta Título</label>
                  <input class="form-control" v-model="item.meta_title_tag"/>
                  <div class="message">
                    {{ validation.firstError("item.meta_title_tag") }}
                  </div>
                </div>
              </div>
              <div class="col-1-3">
                <div class="form-group">
                  <label for="">Meta Descrição</label>
                  <input
                      class="form-control"
                      v-model="item.meta_description"
                      height="auto"
                  />
                  <div class="message">
                    {{ validation.firstError("item.meta_description") }}
                  </div>
                </div>
              </div>
              <div class="col-2-3">
                <div class="form-group">
                  <label for="">Descrição</label>
                  <input class="form-control" v-model="item.descricao"/>
                  <div class="message">
                    {{ validation.firstError("item.descricao") }}
                  </div>
                </div>
              </div>
              <div class="col-1-3" style="margin-bottom: 20px">
                <div class="form-group">
                  <label>Sistema Exibição</label>
                  <label class="checkbox">
                    <input
                        type="checkbox"
                        v-model="item.acessab2b"
                        :value="item.acessab2b"
                    />
                    &ensp; B2B
                  </label>
                  <label class="checkbox" v-show="item.tipo_uso !== 'TERMOS_USO'">
                    <input
                        type="checkbox"
                        v-model="item.acessacallcenter"
                        :value="item.acessacallcenter"
                    />
                    &ensp; Callcenter
                  </label>
                  <label class="checkbox" v-show="item.tipo_uso !== 'TERMOS_USO'">
                    <input
                        type="checkbox"
                        v-model="item.acessafv"
                        :value="item.acessafv"
                    />
                    &ensp; Força de Vendas
                  </label>
                </div>
              </div>
              <div class="col-1-1 add-image">
                <label>
                  <image-picker
                      label="Imagem Principal"
                      placeholder="Colar URL ou clique ao lado para upload"
                      v-model="item.imagem_principal"
                  />
                </label>
                <div class="message">
                  {{ validation.firstError("item.imagem_principal") }}
                </div>
              </div>
              <div class="col-1-1">
                <div class="form-group">
                  <editor-conteudo :item="this.item"></editor-conteudo>

                  <div class="message">
                    {{ validation.firstError("item.conteudo") }}
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button
                  :class="{
                  'button button-success': true,
                  'button-disabled': validation.hasError(),
                }"
                  v-on:click="handleSubmit"
              >
                {{ !!item.id ? 'Alterar' : 'Cadastrar' }}
              </button>
              <button class="button" v-on:click="close">Fechar</button>
            </div>
          </CTab>
          <CTab title="Confirmações" v-show="item.tipo_uso === 'TERMOS_USO'">
            <ConfirmacoesTab :confirmacoes="confirmacoes" :confirmacoesPaginator="confirmacoesPaginator"
                             :loadConfirmacoes="loadConfirmacoes"/>
          </CTab>
          <CTab title="Histórico" v-show="item.tipo_uso === 'TERMOS_USO'">
            <HistoricoTab :historico="historico" :historicoPaginator="historicoPaginator"
                          :loadHistorico="loadHistorico"/>
          </CTab>
        </CTabs>
      </div>
    </div>
  </modal>
</template>
<script>
import {Validator} from "simple-vue-validator";
import CloseModal from "../../components/CloseModal";
import ImagePicker from "../../components/ImagePicker";
import EditorConteudo from "../../components/EditorConteudo";
import ConfirmacoesTab from "./ConfirmacoesTab.vue";
import HistoricoTab from "./HistoricoTab.vue";

export default {
  name: "termosLgpdEdit",
  props: [
    "item",
    "create",
    "update",
    "close",
    "descSingular",
    "tiposTermo",
    "historico",
    "historicoPaginator",
    "confirmacoes",
    "confirmacoesPaginator",
    "loadHistorico",
    "loadConfirmacoes",
  ],
  components: {HistoricoTab, ConfirmacoesTab, CloseModal, ImagePicker, EditorConteudo},
  validators: {
    "item.titulo": (value) => Validator.value(value).required(),
    "item.descricao": (value) => Validator.value(value).required(),
    "item.ativo": (value) => Validator.value(value).required(),
    "item.ordem": (value) => Validator.value(value).required(),
    "item.tipo_uso": (value) => Validator.value(value).required(),
  },
  methods: {
    handleSubmit() {
      this.$validate().then((success) => {
        if (success) {
          if (this.item.id) {
            this.update();
          } else {
            this.create(this.item)
          }
        }
      });
    },
  },
};
</script>
