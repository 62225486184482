<template>
  <div class="xrow">
    <div class="filters row" style="margin-top:0; margin-right: 10px">
      <div>
        <span class="filters__search" style="margin-right: 10px">
          <input
              type="text"
              v-model="search"
              placeholder="Buscar por..."
              @input="debounceConfirmacoes"
          />
          <img
              style="position: relative; right: -10px"
              src="data:image/svg+xml;utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8IS0tIEdlbmVyYXRvcjogQWRvYmUgSWxsdXN0cmF0b3IgMTkuMC4wLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVmVyc2lvbjogNi4wMCBCdWlsZCAwKSAgLS0+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4PSIwcHgiIHk9IjBweCIgdmlld0JveD0iMCAwIDU2Ljk2NiA1Ni45NjYiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDU2Ljk2NiA1Ni45NjY7IiB4bWw6c3BhY2U9InByZXNlcnZlIiB3aWR0aD0iMTZweCIgaGVpZ2h0PSIxNnB4Ij4KPHBhdGggZD0iTTU1LjE0Niw1MS44ODdMNDEuNTg4LDM3Ljc4NmMzLjQ4Ni00LjE0NCw1LjM5Ni05LjM1OCw1LjM5Ni0xNC43ODZjMC0xMi42ODItMTAuMzE4LTIzLTIzLTIzcy0yMywxMC4zMTgtMjMsMjMgIHMxMC4zMTgsMjMsMjMsMjNjNC43NjEsMCw5LjI5OC0xLjQzNiwxMy4xNzctNC4xNjJsMTMuNjYxLDE0LjIwOGMwLjU3MSwwLjU5MywxLjMzOSwwLjkyLDIuMTYyLDAuOTIgIGMwLjc3OSwwLDEuNTE4LTAuMjk3LDIuMDc5LTAuODM3QzU2LjI1NSw1NC45ODIsNTYuMjkzLDUzLjA4LDU1LjE0Niw1MS44ODd6IE0yMy45ODQsNmM5LjM3NCwwLDE3LDcuNjI2LDE3LDE3cy03LjYyNiwxNy0xNywxNyAgcy0xNy03LjYyNi0xNy0xN1MxNC42MSw2LDIzLjk4NCw2eiIgZmlsbD0iIzAwNDE2ZiIvPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8L3N2Zz4K"
          />
        </span>
      </div>
    </div>
    <div class="table-responsive">
      <datatable
          :columns="table.columns"
          :data="confirmacoes"
          class="table table-hover"
      >
        <template slot-scope="{ row }">
          <tr>
            <td>
              {{ row.colaborador_id }} - {{ row.colaborador_nome }}
            </td>
            <td>
              {{ row.datahora_aceite }}
            </td>
          </tr>
        </template>
      </datatable>
    </div>
    <pagination
        :data="confirmacoesPaginator"
        :limit="1"
        @pagination-change-page="getConfirmacoes"
    />
  </div>
</template>

<script>
import _debounce from "lodash/debounce";

export default {
  name: 'ConfirmacoesTab',
  props: ['confirmacoes', 'confirmacoesPaginator', 'loadConfirmacoes'],
  methods: {
    getConfirmacoes(page = 1, take = 12) {
      this.loadConfirmacoes(page, take, this.search);
    }
  },
  data() {
    return {
      debounceConfirmacoes: _debounce(this.getConfirmacoes, 500),
      table: {
        filter: "",
        columns: [
          {label: "Colaborador", field: "colaborador_nome", sortable: false},
          {label: "Data da confirmação", field: "datahora_aceite", sortable: false},
        ],
        paginator: {},
      },
      search: '',
    }
  },
};
</script>